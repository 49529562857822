@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import '../../assets/styles/global/variables';
@import '~bootstrap/scss/mixins';
@import '../../assets/styles/global/mixins';

.customers-slider {
  &__title {
    padding: 1.75rem 0;
    margin-bottom: 0;

    @include media-breakpoint-down(lg) {
      padding: 24px 0;
    }

    .page-template-template-digital-marketing-agency & {
      max-width: 56.75rem;
      margin-right: auto;
      margin-left: auto;

      @include media-breakpoint-down(lg) {
        max-width: 510px;
      }
    }

    .text--body {
      margin: 2.5rem 0 0.5rem;
      opacity: 0.4;
    }

    h2, .h2 {
      font-weight: 500;

      br {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    h2:last-child {
      margin-bottom: 0.75rem;
    }

    h3, .h3 {
      font-size: 2.25rem;
      font-weight: 400;
      line-height: (52 / 36);

      @include media-breakpoint-down(lg) {
        font-size: 22px;
        line-height: 120%;
      }
    }
  }

  &__item {
    display: block;
    padding-bottom: 52%;

    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $black;
      opacity: 0.6;
      transition: $transition-base;
    }

    &:hover {
      &::after {
        background: $primary;
        opacity: 0.3;
      }
    }
  }

  &__bg {
    z-index: 1;
  }

  &__logo {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50px;
    object-fit: contain;
    object-position: center;
    transform: translate(-50%, -50%);
  }

  .splide__slide {
    width: 40rem;

    @include media-breakpoint-down(xl) {
      width: 500px;
    }

    @include media-breakpoint-down(md) {
      width: 400px;
    }

    @include media-breakpoint-down(sm) {
      width: 300px;
    }

    //width: 35vw;
    //
    //@include media-breakpoint-down(xl) {
    //  width: 40vw;
    //}
    //
    //@include media-breakpoint-down(lg) {
    //  width: 50vw;
    //}
    //
    //@include media-breakpoint-down(md) {
    //  width: 70vw;
    //}
    //
    //@include media-breakpoint-down(sm) {
    //  width: 85vw;
    //}
  }
}

@include color-mode(dark) {
  .section--customers-slider {
    background-color: $black !important;
  }
}

